<template>
  <article>
    <Image
      v-if="note.featured_image"
      :media="note.featured_image"
      :alt="note.title"
      lazy
      responsive
      class="ag-block__featured-image"
      :class="`ag-block__featured-image--${note.image_display}`"
    />

    <div class="ag-block__body">
      <h3 v-if="note.title" class="ag-block__title">
        <a v-if="note.url" :href="note.url" :target="target" rel="nofollow noindex" v-html="$markdown(note.title)" @click.prevent="onClick(block)" />
        <span v-else v-html="$markdown(note.title)" />
      </h3>
      <p v-if="note.synopsis" class="ag-block__description" v-html="note.synopsis"></p>
      <div v-if="note.url && note.call_to_action_text" class="ag-block__cta">
        <Button :href="note.url" :target="target" as="a" class="ag-block__button" rel="nofollow noindex" @click.prevent="onClick(block)">
          {{ note.call_to_action_text }}
        </Button>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: Block;
}>();

const note: NoteBlock = props.block.item;

const target = note?.url_type === "link" ? "_blank" : undefined;

const { onClick } = useBlock();
</script>

<style lang="postcss">
.ag-block--note-simple4 {
  @apply relative overflow-hidden rounded-lg border;

  .ag-block__featured-image {
    @apply w-full;

    &--cover {
      @apply max-h-48 object-cover;
    }
  }

  .ag-block__body {
    @apply w-full p-8;
  }
}
</style>
